import React, { useEffect, useState } from 'react';
import './AppsContainer.css';
import Application from './Application/Application'; // Assurez-vous d'importer votre composant Application

const AppsContainer = () => {
    const [apps, setApps] = useState([]);

    useEffect(() => {
        // Simuler le chargement des applications
        const getApps = async () => {
            // Remplacez par votre logique pour obtenir les applications
            const fetchedApps =await (await fetch('https://backend.aynos.net/api/apps/getAllApps')).json();
            setApps(fetchedApps);
        };

        getApps();
    }, []);

    useEffect(() => {
        const adjustSpacing = () => {
            const container = document.querySelector('.AppsContainer');
            if (!container) return;

            const items = container.children;
            if (items.length === 0) return;

            const containerStyle = getComputedStyle(container);
            const containerWidth = container.clientWidth - parseFloat(containerStyle.paddingLeft) - parseFloat(containerStyle.paddingRight);
            const itemWidth = items[0].clientWidth;

            // Calculer le nombre d'éléments par ligne
            const itemsPerRow = Math.floor(containerWidth / itemWidth);
            console.log("Container Width : "+container.clientWidth);
            console.log("ItemWidth : " + itemWidth);
            console.log("Container Width without gap : "+containerWidth);

            console.log(itemsPerRow);
            // Calculer le gap dynamique
            const totalGap = containerWidth - (itemsPerRow * itemWidth);
            const gap = totalGap / (itemsPerRow - 1);

            // Appliquer le gap dynamique
            container.style.gap = `${gap}px`;
        };

        // Appeler la fonction après le rendu initial
        adjustSpacing();

        // Ajouter un écouteur d'événements pour ajuster la classe lors du redimensionnement de la fenêtre
        window.addEventListener('resize', adjustSpacing);

        // Nettoyer l'écouteur d'événements lors du démontage du composant
        return () => {
            window.removeEventListener('resize', adjustSpacing);
        };
    }, [apps]);

    return (
        <div className="AppsContainer">
            {apps.map((app, index) => (
                <Application title={app.name} iconPath={app.iconUrl} link= {app.appUrl}/>
            ))}
            {/* Ajoutez d'autres applications ici */}
        </div>
    );
};

export default AppsContainer;