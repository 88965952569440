import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './NetworkDevice.css';
import DevicePopup from './DevicePopup/DevicePopup';

const NetworkDevice = ({ name, status, ip, mac, last_seen }) => {
    const [showPopup, setShowPopup] = useState(false);

    const handleDeviceClick = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    return (
        <div className="network-device" onClick={handleDeviceClick}>
            <h2>{name}</h2>
            <div className='informations'>
                <div className='status'>
                    <div className={`status-indicator ${status ? 'online' : 'offline'}`}></div>
                    <p>Last Seen: {last_seen}</p>
                </div>
                <div className='addresses'>
                    <p>IP Address: {ip}</p>
                    <p>MAC Address: {mac}</p>
                </div>
            </div>
            {showPopup && (
                <DevicePopup
                    name={name}
                    status={status}
                    ip={ip}
                    mac={mac}
                    last_seen={last_seen}
                    onClose={handleClosePopup}
                />
            )}
        </div>
    );
};

NetworkDevice.propTypes = {
    name: PropTypes.string.isRequired,
    status: PropTypes.bool.isRequired,
    ip: PropTypes.string.isRequired,
    mac: PropTypes.string.isRequired,
    last_seen: PropTypes.string.isRequired,
};

export default NetworkDevice;