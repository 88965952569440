import React from 'react';
import PropTypes from 'prop-types';
import './DevicePopup.css';
import { FaTimes } from "react-icons/fa";
import { useNotification } from '../../../NotificationProvider/NotificationProvider';

const sendWakeOnLanRequest = async (addNotification, mac, ip) => {
    addNotification('Sending Wake on LAN request...', 'info');
    try {
        await fetch(`https://backend.aynos.net/api/network/wakeOnLan?macAddress=${mac}&ipAddress=${ip}`);

    } catch (error) {
        console.error(error);
    }
}

const DevicePopup = ({ name, status, ip, mac, last_seen, onClose }) => {
    const { addNotification } = useNotification();

    return (
        <div className="device-popup-overlay" onClick={onClose}>
            <div className="device-popup" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}><FaTimes /></button>
                <h2>{name}</h2>
                <p>Status: {status ? 'Connected' : 'Disconnected'}</p>
                <p>IP Address: {ip}</p>
                <p>MAC Address: {mac}</p>
                <p>Last Seen: {last_seen}</p>
                {!status &&
                    <button className="wake-button" onClick={() => sendWakeOnLanRequest(addNotification, mac, ip)}>Wake on LAN</button>
                }
            </div>
        </div>
    );
};

DevicePopup.propTypes = {
    name: PropTypes.string.isRequired,
    status: PropTypes.bool.isRequired,
    ip: PropTypes.string.isRequired,
    mac: PropTypes.string.isRequired,
    last_seen: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default DevicePopup;