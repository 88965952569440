import React, { useEffect } from 'react';
import './AboutPage.css';
import "../components/NetworkDevices/NetworkDevices";
import NetworkDevices from '../components/NetworkDevices/NetworkDevices';

function AboutPage() {
  useEffect(() => {
    document.title = "Aynos - About";
  }, []);

  return (
<div className="about-page">
      <h2>About Page</h2>
      <NetworkDevices/>
</div>
  );
}

export default AboutPage;
