import React from 'react';
import './Application.css';


const Application = ({title, iconPath, link}) => {

    return (
        <a href={link} target="_blank" rel="noopener noreferrer" className="appLink">
        <div className="application">
            <img src={iconPath} alt="icon" />
            <h2>{title}</h2>
        </div>
        </a>
    );
}

export default Application;