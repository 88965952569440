import React from 'react';
import { FaTimes } from 'react-icons/fa';
import './BottomDrawer.css';
import ThemeSwitcher from '../../ThemeSwitcher/ThemeSwitcher';

const BottomDrawer = ({ isOpen, toggleDrawer }) => {
  return (
    <div className={`bottom-drawer ${isOpen ? 'open' : ''}`}>
      <div className="drawer-content">
        <ul>
        <a href="/"><li>Home</li></a>
        <a href="/Apps"><li>Apps</li></a>
        <a href="/About"><li>About</li></a>
        <li class="themeswitcher"><ThemeSwitcher/></li>
      </ul>
      </div>
    </div>
  );
};

export default BottomDrawer;
