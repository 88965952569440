import React, {useState, useEffect} from "react";
import Cards from "../Cards";
import "./PerformencesCard.css";
import ProgressBar from "../../ProgressBar/ProgressBar";


const getInfo = async () => {
  try {
    const res = await fetch(`https://backend.aynos.net/api/performances`);
  const data = await res.json();
  return data;
  }
  catch (error) {
    console.log(error);
    return null;
  }
};

const actualizeData = async (setPercentageCPU, setPercentageRAM, setPowerConsumption) => {
  const data = await getInfo();
  if (data!==null) {
    setPercentageCPU(data.cpuUsage);
    setPercentageRAM(data.ramUsage);
    setPowerConsumption(data.powerConsumption);
  }
}

const PerformencesCard = () => {
  const [percentageCPU, setPercentageCPU] = useState(0);
  const [percentageRAM, setPercentageRAM] = useState(0);
  const [powerConsumption, setPowerConsumption] = useState(0);

  useEffect( () => {
    actualizeData(setPercentageCPU, setPercentageRAM, setPowerConsumption);

    const interval = setInterval(async() => {
      actualizeData(setPercentageCPU, setPercentageRAM, setPowerConsumption);
    }, 10*1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);


  let child = (
    <ul>
      <li>
        <p>CPU usage</p>
        <ProgressBar percentage={percentageCPU} />
      </li>
      <li>
        <p>RAM usage</p>
        <ProgressBar percentage={percentageRAM} />
      </li>
    </ul>);
  return Cards({ title: "Performances", children: child });
}


export default PerformencesCard;