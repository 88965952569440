import React from 'react';
import PerformencesCard from '../Cards/PerformencesCard/PerformencesCard';
import Cards from '../Cards/Cards';
import './Dashboard.css';
import { FaPlus } from "react-icons/fa";

function Dashboard() {
  return (
    <div className="dashboard">
      <div className="vued">
      <h2>Vue d'ensemble</h2>
      <button className='addButton' id='addCard'><FaPlus /></button>
      </div>
      <div className="cards">
        <Cards title="Exemple 1" children=""/>
        <Cards title="Exemple 2" children=""/>

        <PerformencesCard />  
      </div>

      
      
    </div>
  );
}

export default Dashboard;
