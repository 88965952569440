import React, { useEffect, useState } from 'react';
import './NetworkDevices.css';
import NetworkDevice from './NetworkDevice/NetworkDevice';
import { TbReload } from "react-icons/tb";
import { useNotification } from '../NotificationProvider/NotificationProvider';


const NetworkDevices = () => {
    const [devices, setDevices] = useState([]);
    const [filter, setFilter] = useState("all");
        const { addNotification } = useNotification();
    

    const fetchDevices = async () => {
        try {
            const response = await fetch('https://backend.aynos.net/api/network/getAllDevices');
            setDevices(await response.json());
        } catch (error) {
            setDevices([{ "ip": "192.168.1.58", "mac": "00:24:d4:7c:a3:51", "hostname": "Unknown", "lastSeen": "2024-12-13T10:24:15", "connected": true }, { "ip": "192.168.1.254", "mac": "00:24:d4:a4:ed:92", "hostname": "Unknown", "lastSeen": "2024-12-13T10:24:16", "connected": true }, { "ip": "192.168.1.35", "mac": "02:42:20:57:f0:14", "hostname": "Unknown", "lastSeen": "2024-12-13T07:44:15", "connected": false }, { "ip": "192.168.1.195", "mac": "28:d1:27:87:b8:48", "hostname": "Unknown", "lastSeen": "2024-12-13T10:24:16", "connected": true }, { "ip": "192.168.1.56", "mac": "48:22:54:f9:ff:a4", "hostname": null, "lastSeen": "2024-12-13T10:21:51", "connected": false }]);
        }
    };

    useEffect(() => {
        

        fetchDevices();
    }, []);

    const [filteredDevices, setFilteredDevices] = useState([]);

    useEffect(() => {
        setFilteredDevices(devices);
    }, [devices]);

    const handleFilterChange = () => {
        if (filter === 'all'){
            setFilter('connected');
            setFilteredDevices(devices.filter(device => device.connected === true));

        }else if (filter === 'connected'){
            setFilter('disconnected');
            setFilteredDevices(devices.filter(device => device.connected === false));

        } else if (filter === 'disconnected'){
            setFilter('all');
            setFilteredDevices(devices);

        }
    };




    return (
        <div className='network-devices-container'>
            <h1>Network Devices</h1>
            <div className="filters">
            <div className={`filter ${filter}`} onClick={handleFilterChange}></div>
            <TbReload size={25} onClick={() => {fetchDevices();addNotification("Reloading network devices")}}/>
            </div>
            <ul className="network-devices">
                {filteredDevices.map((device) => (
                    <li key={device.ip}>
                        <NetworkDevice name={device.hostname} status={device.connected} ip={device.ip} mac={device.mac} last_seen={device.lastSeen} />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default NetworkDevices;