import React, { useEffect } from 'react';
import Dashboard from '../components/Dashboard/Dashboard';
import './HomePage.css';

function HomePage() {

    useEffect(() => {
        document.title = "Aynos - Dashboard";
    }, []);
    
    return (
            <Dashboard />

    );
}

export default HomePage;
