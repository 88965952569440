import React, { useState } from 'react';
import Sidebar from '../components/Navigation/Sidebar/Sidebar';
import Header from '../components/Navigation/Header/Header';
import Dashboard from '../components/Dashboard/Dashboard';
import Application from '../components/AppsContainer/Application/Application';
import AppsContainer from '../components/AppsContainer/AppsContainer';

function AppsPage() {

  

  return (
    <div className="Aynos - Apps">

        <AppsContainer/>

    </div>
  );
}

export default AppsPage;
