import React from 'react';
import './Sidebar.css'; // Import CSS for styling
import { Link } from 'react-router-dom';
import ThemeSwitcher from '../../ThemeSwitcher/ThemeSwitcher';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className="close-btn" onClick={toggleSidebar}>
        &times;
      </button>
      <h2>Dashboard</h2>
      <ul>
        <a href="/"><li>Home</li></a>
        <a href="/Apps"><li>Apps</li></a>
        <a href="/About"><li>About</li></a>
        <li class="themeswitcher"><ThemeSwitcher/></li>
      </ul>
    </div>
  );
};

export default Sidebar;
