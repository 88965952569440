import React, { useState, useEffect } from 'react';
import './Header.css';
import aynosLogo from '../../../images/logo.png';
import { useTheme } from '../../../ThemeContext';
import { GiHamburgerMenu } from "react-icons/gi";



function Header({ toggleSidebar, isMobile ,isSidebarVisible }) {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const { theme, updateTheme } = useTheme();


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setIsHeaderVisible(false); // Cache le header au scroll vers le bas
      } else {
        setIsHeaderVisible(true); // Affiche le header au scroll vers le haut
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);


  let headerClasses = 'header ' + (isHeaderVisible ? 'visible' : 'hidden');



  return (
    <div className={headerClasses}>
      {!isMobile &&<button
        onClick={toggleSidebar}
        className={`toggle-sidebar-btn ${isSidebarVisible ? 'rotated' : ''}`}
      >
        <GiHamburgerMenu />
      </button>}
      <a href='/'>
      <div className="title">
        
        <img src={aynosLogo} alt="Aynos Logo" className={`logo ${theme}`} />
        <h1 className="title">DASHBOARD</h1>
        
      </div>
      </a>
    </div>
  );
}


export default Header;
