import React, { useEffect, useRef, useState } from "react";
import "./AnimatedBackground.css"; // Import du CSS externe

const initParticles = (element) => {
  
  const options = {
    dotColor: '#ccc',
    lineColor: '#ccc',
    density: 10000,
    particleRadius: 7,
    lineWidth: 1,
    proximity: 100,
    parallax: true,
    parallaxMultiplier: 5,
    speedMutiplier: 0.5,
  };

  let canvas, ctx, particles, raf;
  let mousePosition = { x: 0, y: 0 };
  let touchPosition = { x: 0, y: 0 };


  const createParticles = () => {
    const numParticles = Math.round((canvas.width * canvas.height) / options.density);
    particles = Array.from({ length: numParticles }, () => ({
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      vx: (Math.random() - 0.5) * options.speedMutiplier,
      vy: (Math.random() - 0.5) * options.speedMutiplier,
      ax: 0.0,
      ay: 0.0,
    }));
  };

  const drawParticles = () => {
    const computedStyles = getComputedStyle(canvas);
    const dotColor = computedStyles.getPropertyValue('--vivid-color').trim();
    const lineColor = computedStyles.getPropertyValue('--vivid-color').trim();


    ctx.clearRect(0, 0, canvas.width, canvas.height);
    particles.forEach((p) => {
      ctx.beginPath();
      ctx.arc(p.x, p.y, options.particleRadius / 2, 0, Math.PI * 2);
      ctx.fillStyle = dotColor;
      ctx.fill();
      particles.forEach((p2) => {
        const dx = p2.x - p.x;
        const dy = p2.y - p.y;
        const dist = Math.sqrt(dx * dx + dy * dy);
        if ( dist < options.proximity) {
          ctx.beginPath();
          ctx.moveTo(p.x, p.y);
          ctx.lineTo(p2.x, p2.y);
          ctx.strokeStyle = lineColor;
          let opacity = 1;
          opacity =  1 - dist / options.proximity;
  
          ctx.lineWidth = options.lineWidth * opacity*2 ;
          
          ctx.stroke();
        }
      });
    });
  };

  const animate = () => {
    particles.forEach((p) => {
      const dx = p.x - mousePosition.x;
      const dy = p.y - mousePosition.y;
      const dist = Math.sqrt(dx * dx + dy * dy);
      const forceDirectionX = dx / dist;
      const forceDirectionY = dy / dist;
      const maxDistance = 100;
      const force = (maxDistance - dist) / maxDistance;
      const directionX = forceDirectionX * force * options.speedMutiplier;
      const directionY = forceDirectionY * force * options.speedMutiplier;

      const dxTouch = p.x - touchPosition.x;
      const dyTouch = p.y - touchPosition.y;
      const distTouch = Math.sqrt(dxTouch * dxTouch + dyTouch * dyTouch);
      const forceDirectionXTouch = dxTouch / distTouch;
      const forceDirectionYTouch = dyTouch / distTouch;
      const forceTouch = (maxDistance - distTouch) / maxDistance;
      const directionXTouch = forceDirectionXTouch * forceTouch * options.speedMutiplier;
      const directionYTouch = forceDirectionYTouch * forceTouch * options.speedMutiplier;

      if (dist < options.proximity) {
        p.ax += dx * 0.001;
        p.ay += dy * 0.001;
      }

      if (distTouch < options.proximity) {
        p.ax += dxTouch * 0.001;
        p.ay += dyTouch * 0.001;
      }

      p.x += p.vx + p.ax;
      p.y += p.vy + p.ay;

      p.ax *= 0.99;
      p.ay *= 0.99;


      if (p.x > canvas.width || p.x < 0) {p.vx *= -1; p.ax *= -1;};
      if (p.y > canvas.height || p.y < 0) {p.vy *= -1; p.ay *= -1};
    });
    drawParticles();
    raf = requestAnimationFrame(animate);
  };

  canvas = document.createElement("canvas");
  canvas.style.display = "block";
  element.prepend(canvas);
  ctx = canvas.getContext("2d");
  canvas.width = element.offsetWidth;
  canvas.height = element.offsetHeight;

  createParticles();
  animate();

  const handleMouseMove = (event) => {
    mousePosition = { x: event.clientX, y: event.clientY };
  };

  const handleTouchMove = (event) => {
    if (event.touches.length > 0) {
      touchPosition = { x: event.touches[0].clientX, y: event.touches[0].clientY };
    }
  };

  window.addEventListener("mousemove", handleMouseMove);
  window.addEventListener("touchmove", handleTouchMove);

  return () => {
    cancelAnimationFrame(raf);
    window.removeEventListener("mousemove", handleMouseMove);
    element.removeChild(canvas);
  };
};

const ParticleBackground = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const cleanup = initParticles(containerRef.current);
    return cleanup;
  }, []);

  return (
    <div id="particles" ref={containerRef} className="particle-background">
    </div>
  );
};

export default ParticleBackground;
