import React from 'react';
import './Cards.css';


const Cards = ({ title, children }) => {
  return (
    <div className="card">
      <h1>{title}</h1>
      <div className="card-content">
        {children}
      </div>
    </div>
  );
}

export default Cards;