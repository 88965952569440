// src/App.js
import React from 'react';
import AppRoutes from './Routes';
import './App.css';
import Navigation from './components/Navigation/Navigation';
import ParticleBackground from './components/AnimatedBackground/AnimatedBackground';


function App() {
 
  return (
    <div className="app">
      <Navigation />
      <div className="main-content">
      <AppRoutes />
      </div>
      <ParticleBackground />
    </div>
  );
}

export default App;
