import React from 'react';
import { useTheme } from '../../ThemeContext';
import { BsSunFill, BsMoonStarsFill } from 'react-icons/bs'; // Import des icônes
import './ThemeSwitcher.css'

const ThemeSwitcher = () => {
  const { theme, updateTheme } = useTheme();

  // Fonction pour passer au thème suivant (light -> dark -> system -> light)
  const toggleTheme = () => {
    console.log(theme);

    if (theme === 'light') {
      updateTheme('dark');
    } else {
      updateTheme('light');
    }
  };

    // Choisir l'icône à afficher en fonction du thème actuel
  let icon;
  if (theme === 'light') {
    icon = <BsSunFill size={25} className='ThemeIcon' />;
  } else if (theme === 'dark') {
    icon = <BsMoonStarsFill size={25} className='ThemeIcon'/>;
  }


  return (
    <div>
      <div onClick={toggleTheme} style={{ cursor: 'pointer' }}>
        {icon}
      </div>
    </div>
  );
};

export default ThemeSwitcher;
