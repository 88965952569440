import React from 'react';
import { FaHome, FaSearch, FaUser } from 'react-icons/fa'; // Import des icônes
import "./Navbar.css"; // Import du fichier CSS
import { HiDotsHorizontal } from "react-icons/hi";
import { IoApps } from "react-icons/io5";
import { AiFillHome } from "react-icons/ai";


const Navbar = ({toggleDrawer}) => {
  // Fonctions de base pour chaque bouton (modifiez selon vos besoins)
  const handleHomeClick = () => {
    window.location.href = "/";
  };

  const handleAppClick = () => {
    window.location.href = "/Apps";
  };


  return (
    <div className="navbar">
      <button onClick={handleHomeClick} className="nav-button">
        <AiFillHome size={24} />
      </button>
      <button onClick={handleAppClick} className="nav-button">
        <IoApps size={24} />
      </button>
      <button onClick={toggleDrawer} className="nav-button">
        <HiDotsHorizontal size={24} />
      </button>
    </div>
  );
};

export default Navbar;
